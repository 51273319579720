<template>
  <div class="login">
    <section>
      <h1 class="subtitle">Sign in to <strong>Centivo Plan Manager</strong></h1>
      <br/>
      <b-field>
        <b-input type="text" name="email" placeholder="Username" maxlength="75" v-model="username"></b-input>
      </b-field>

      <b-field>
        <b-input placeholder="Password" type="password" v-model="password" password-reveal
        v-on:keyup.native.enter="login">
        </b-input>
      </b-field>

      <div class="button-area">
        <b-field>
          <b-button @click="login">
            Sign In
          </b-button>
        </b-field>
        <b-field>
          <b-button @click="forgotPassword" type="is-text">
            Forgot Password
          </b-button>
        </b-field>
        <b-field>
          <b-button @click="firstTimeLogin" type="is-text">
            First time login
          </b-button>
        </b-field>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'login-form',
  data () {
    // TODO: Remove these defaults
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    async login () {
      this.$emit('loggingIn', true)
      this.$store.dispatch('auth/authenticate', { username: this.username, password: this.password })
        .then((res) => {
          this.$router.push({ path: '/plans' })
          this.$emit('loggingIn', false)
        })
        .catch((err) => {
          console.error(err)
          this.$buefy.toast.open({
            message: 'Credentials not found',
            type: 'is-danger',
            duration: 5000,
            position: 'is-top'
          })
          this.$emit('loggingIn', false)
        })
    },
    firstTimeLogin () {
      this.$router.push({ path: '/first-time-login' })
    },
    forgotPassword () {
      this.$router.push({ path: '/forgot-password' })
    }
  },
  computed: {

  },
  mounted () {
    loadData()
  }
}
function loadData () {
  return {}
}
</script>

<style lang="scss" scoped>
  h3 {
    font-size: large;
    padding-top: 100px;
    text-align: center;
  }
  .button-area {
    display: flex;
    justify-content: space-between;
  }
</style>
