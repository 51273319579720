<template>
  <div class="container">
    <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
    <img src="../assets/Story-Icon-Hero.svg" alt="Centivo" class="logo" />
    <LoginForm @loggingIn="isLoading = $event"/>
  </div>
</template>

<script>
import LoginForm from '@/components/login-form'

export default {
  name: 'login',
  components: {
    LoginForm
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {

  }
}
</script>

<style lang="scss">
.login {
  width: 25%;
  align-content: center;
  align-self: center;
  display: inline-block;
}

.logo {
  display: block;
  margin: auto;
  width: 48px;
  padding-top: 10%;
  margin-bottom: 32px;
}
</style>
